import provinces from 'china-division/dist/provinces.json';
import cities from 'china-division/dist/cities.json';


// 选择到市
const options = provinces.map(province => {
  return {
    label: province.name,
    value: province.code + '/' + province.name,
    children: cities.filter(city=>city.provinceCode == province.code).map(city => {
      return {
        label: city.name,
        value: city.code + '/' + city.name,
        children: [],
      }
    }),
  }
});
export default options;