<template>
  <div>
    <a-drawer :title="`${form.id ? '编辑' : '新建'}部门`" :width="720" :visible="visible"
      :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="onClose">
      <a-form :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="部门名称" name="dept_name">
              <a-input v-model:value="form.dept_name" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="区域级别" name="level">
              <a-select v-model:value="form.level" :options="[
                { label: '国家', value: 1 },
                { label: '省', value: 2 },
                { label: '市', value: 3 },
                { label: '区', value: 4 },
              ]" placeholder="请选择" @change="handleChange"></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="areas[form.level - 1]">
            <a-form-item label="所属区域">
              <a-cascader placeholder="请选择" :options="areas[form.level - 1]" v-model:value="ssqy"></a-cascader>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="排列序号" name="sort_num">
              <a-input type="number" v-model:value="form.sort_num" placeholder="请输入" />
            </a-form-item>
          </a-col>
        </a-row>
        <!-- <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="附件" name="icon">
              <UploadFile v-model:value="form.icon" />
            </a-form-item>
          </a-col>
        </a-row> -->
      </a-form>
      <template #extra>
        <a-space>
          <a-button @click="onClose">取消</a-button>
          <a-button :loading="createLoading" type="primary" @click="onConfirm">提交</a-button>
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
// import UploadFile from '@/components/common/uploadFile.vue';
import service from '@/service/service';
import { message } from 'ant-design-vue';
import options_provinces from '@/util/address-options_provinces';
import options_cities from '@/util/address-options_cities';
import options from '@/util/address-options';
const rules = {
  dept_name: [{
    required: true,
    message: '请输入部门名称',
  }],
  sort_num: [{
    required: true,
    message: '请输入部门序号',
  }],
};
const formProp = {
  id: '',
  level: '',
  dept_name: '',
  sort_num: '',
  sheng_code: '',//省code
  sheng: '',//省名称
  shi_code: '',//市code
  shi: '',//市名称
  qu_code: '',//区code
  qu: '',//区名称
}
export default {
  components: {
    // UploadFile
  },
  props: ['current'],
  data() {
    return {
      rules,
      areas: [null, options_provinces, options_cities, options],
      ssqy: '',
      form: {
        ...formProp
      },
      visible: false,
      createLoading: false,
    }
  },
  watch: {
    current: {
      handler(nowCurrent) {
        if (nowCurrent) {
          let temp = {};
          for (let key in formProp) {
            temp[key] = nowCurrent[key];
          }
          if (nowCurrent.sheng_code) {
            let area_temp = [
              nowCurrent?.sheng_code ? (nowCurrent.sheng_code + '/' + nowCurrent.sheng) : '',
              nowCurrent?.shi_code ? (nowCurrent.shi_code + '/' + nowCurrent.shi) : '',
              nowCurrent?.qu_code ? (nowCurrent.qu_code + '/' + nowCurrent.qu) : '',
            ];
            this.ssqy = area_temp.splice(0, nowCurrent.level ? (nowCurrent.level - 1) : 3)
          }
          this.form = temp;
        }
      },
      immediate: true
    }
  },
  mounted() {
    // this.form.id = this.current?.id || '';
  },
  methods: {
    onClose() {
      this.visible = false;
      this.onReset();
      this.$emit('close');
    },
    onReset() {
      let temp = { ...formProp };
      this.form = temp;
    },
    handleChange(e) {
      this.ssqy = this.ssqy.splice(0, e ? (e - 1) : 3)
    },
    onShow() {
      this.visible = true;
    },
    async onConfirm() {
      this.createLoading = true;
      const api = this.form.id ? 'edit_dept' : 'add_dept';
      const { code, msg } = await service[api]({
        ...this.form,
        sheng_code: this.ssqy?.[0]?.split('/')[0] || '',
        sheng: this.ssqy?.[0]?.split('/')[1] || '',
        shi_code: this.ssqy?.[1]?.split('/')[0] || '',
        shi: this.ssqy?.[1]?.split('/')[1] || '',
        qu_code: this.ssqy?.[2]?.split('/')[0] || '',
        qu: this.ssqy?.[2]?.split('/')[1] || '',
      })
      this.createLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.$emit('refreshList');
        this.onClose();
      }
    }
  }
}
</script>